import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { sHtml } from '../clever-ui-kit/style'
import { T } from '../clever-ui-kit/typo'
import { sTitle, sBlog, sTheme } from './blog'

// CONTEXT
import { StateContext, DispatchContext, initialState } from '../context'

// COMPONENTS CONST
import SEO from '../components/seo'
import { IntroBlog } from '../clever-ui-kit/intros'
import ContactForm from '../clever-ui-kit/forms/ContactForm'

export default function ProjectTemplate({ data }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState.language
  const theme = state?.theme ? state.theme : initialState.theme

  const project = data.thisProject?.frontmatter
  const html = data.thisProject?.html
  // console.log(data)
  /*eslint-disable */
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    if (project.language)
      dispatch({ type: 'SET_LANGUAGE', payload: project.language })
  }, [])
  /*eslint-enable */

  return (
    <>
      <SEO
        title={project.title}
        description={project.description}
        image={project.image?.publicURL}
        slug={'/' + project.project_slug.toLowerCase().trim()}
      />
      <IntroBlog
        title={project.title}
        subtitle={project.description}
        image={project.image}
        theme={theme}
        language={project.language}
      />
      <div css={sTitle}>
        <T
          d={64}
          t={48}
          m={32}
          mb={0.5}
          bold
          variant="h1"
          extraCss={sTitle.headline}>
          {project.title}
        </T>
        <T d={20} t={18} m={14} o={1} normal>
          {project.description}
        </T>
      </div>
      <article
        css={[sHtml, sBlog, sTheme(theme)]}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div css={{ marginBottom: '4rem' }}>
        <ContactForm theme={theme} />
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String) {
    thisProject: markdownRemark(
      fileAbsolutePath: { regex: "/projects/" }
      frontmatter: { project_slug: { eq: $slug } }
    ) {
      html
      frontmatter {
        title
        project_slug
        language
        description
        image {
          name
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 100
              toFormat: JPG
              jpegProgressive: true
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
